import { AuthService } from './core/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable, filter, map } from 'rxjs';
@Injectable()
export class AppInfoService {
  constructor(
    private httpclient: HttpClient,
    private authservice: AuthService
  ) {}

  public get title() {
    return `HBW Central ng ${environment.production ? '' : '(!!TEST!!)'} `;
  }

  public get Mandant$(): Observable<string> {
    return this.authservice.SelectedMandant$.pipe(
      filter((data) => data !== null),
      map((data) => data?.beschreibung || '')
    );
  }

  public get currentYear() {
    return new Date().getFullYear();
  }

  public get AppVersion() {
    return environment.appVersion;
  }

  public get BackendVersion() {
    return this.authservice.backendVersion?.version;
  }
  public get BackendUptime() {
    return this.authservice.backendVersion?.uptime;
  }
}
