import { DateTime } from 'luxon';
import { version } from './../../../../../environments/version';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  forkJoin,
  map,
  retry,
  switchMap,
  tap,
} from 'rxjs';
import { Mandant, MandantenService } from '../../mandanten.service';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

const API_ENDPOINT = `${environment.BACKEND_URL}/user`;
const API_VERSION_ENDPOINT = `${environment.BACKEND_VERSION}`;

export interface IUser {
  email: string;
  oid: string;
  givenName: string;
  familyName: string;
  vorname?: string;
  nachname?: string;
  _mandant_id?: number;
  _id?: number;
}

export interface IVersion {
  version: string;
  uptime: string;
}

const defaultPath = '/';

@Injectable()
export class AuthService implements OnDestroy {
  private storageListenerUnlisten: () => void;
  private startUpUrl = '';

  private _userInfo$ = new BehaviorSubject<IUser | null>(null);
  private _hasValidToken$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  private _hasUserInfo$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _hasMandantSelected$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  private _selectedMandantId$: BehaviorSubject<number> = new BehaviorSubject(
    this.getInitialMandantId()
  );

  private _isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  private _Mandanten$: BehaviorSubject<Array<Mandant> | null> =
    new BehaviorSubject<Array<Mandant> | null>(null);

  private _initialRequestedUrl: string = '';

  private _backendVersion$ = new BehaviorSubject<IVersion | null>(null);

  constructor(
    private router: Router,
    private oAuthService: OAuthService,
    private httpClient: HttpClient,
    private mandantService: MandantenService,
    private titleService: Title,
    @Inject(DOCUMENT) private document: Document
  ) {
    // Register for Events
    this.oAuthService.events
      .pipe(
        tap((event: OAuthEvent) => {
          if (['session_terminated', 'session_error'].includes(event.type)) {
            this._hasValidToken$.next(false);
          }

          if (['token_refreshed', 'token_received'].includes(event.type)) {
            this._hasValidToken$.next(this.oAuthService.hasValidAccessToken());
          }

          if (['token_expires'].includes(event.type)) {
            //this.login();
          }
        })
      )
      .subscribe();

    this._hasValidToken$
      .asObservable()
      .pipe(
        tap((x) => console.log('Received change', x)),
        distinctUntilChanged(),
        tap((hasValidToken) => {
          // Reset User Info if no token is here
          if (hasValidToken === false) {
            this._hasUserInfo$.next(false);
          }
          console.log('Valid Token OK USER next', hasValidToken);
        }),
        filter((hasValidToken) => hasValidToken === true),
        tap(() => console.log('Try to load user Info')),
        switchMap(() => {
          console.log('SWMap');
          return forkJoin({
            user: this.httpClient
              .get<IUser>(`${API_ENDPOINT}/`)
              .pipe(retry({ count: 4, delay: 2000 })),
            version: this.httpClient
              .get<IVersion>(`${API_VERSION_ENDPOINT}/`)
              .pipe(retry({ count: 4, delay: 2000 })),
            mandanten: this.mandantService.Store$.pipe(first()),
          });
        }),
        tap((x) => {
          console.log('UserInfo', x.user, x.user.oid);
          this._userInfo$.next(x.user);
          this._hasUserInfo$.next(x.user && x.user.oid ? true : false);
          this._Mandanten$.next(x.mandanten);
          this._backendVersion$.next(x.version);

          const initialMandantId = this.getInitialMandantId();
          if (
            initialMandantId &&
            x.mandanten.findIndex(
              (mandant) => mandant._id === initialMandantId
            ) > -1
          ) {
            this._selectedMandantId$.next(initialMandantId);
          } else {
            if (x.user._mandant_id) {
              this._selectedMandantId$.next(x.user._mandant_id);
            } else {
              this._selectedMandantId$.next(0);
            }
          }

          if (initialMandantId > 0) {
            if (this.InitialRequestedUrl) {
              this.router.navigateByUrl(this.InitialRequestedUrl);
              this.InitialRequestedUrl = '';
              return;
            }
          }
          this.router.navigate(['home']);
        })
      )
      .subscribe();

    combineLatest([this._hasValidToken$, this._hasUserInfo$])
      .pipe(
        tap(([hasValidToken, hasUserInfo]) => {
          console.log('Auth', hasValidToken, hasUserInfo);
          this._isAuthenticated$.next(hasValidToken && hasUserInfo);
        })
      )
      .subscribe();

    this._selectedMandantId$
      .asObservable()
      .pipe(
        tap((mandantId) => {
          this.mandantService.currentMandantId = mandantId;
          console.log('Setting Session to', mandantId);
          sessionStorage.setItem('mandantId', mandantId.toString());
        })
      )
      .subscribe();

    this.storageListenerUnlisten = () => {};
  }

  public get InitialRequestedUrl(): string {
    return this._initialRequestedUrl;
  }

  public get SelectedMandant$(): Observable<Mandant | null> {
    return combineLatest([
      this._Mandanten$.asObservable(),
      this._selectedMandantId$,
    ]).pipe(
      map(([mandanten, mandantId]) => {
        if (mandanten && mandanten.length > 0 && mandantId && mandantId > 0) {
          return mandanten.find((mandant) => mandant._id === mandantId) || null;
        }

        return null;
      })
    );
  }

  public get isAuthenticated(): boolean {
    return this._isAuthenticated$.getValue();
  }

  public get MandantId(): number {
    return this._selectedMandantId$.getValue();
  }

  public get isAuthenticated$(): Observable<boolean> {
    return this._isAuthenticated$.asObservable();
  }

  public get isMandantSelected$(): Observable<boolean> {
    return combineLatest([
      this.isAuthenticated$,
      this._selectedMandantId$.asObservable(),
    ]).pipe(map((val) => val[0] === true && val[1] > 0));
  }

  public get userInfo$(): Observable<IUser | null> {
    return this._userInfo$.asObservable();
  }

  public get userInfo(): IUser | null {
    return { ...this._userInfo$.getValue() } as IUser | null;
  }

  public set InitialRequestedUrl(uri: string) {
    console.log('set uri to', uri);
    this._initialRequestedUrl = uri;
  }

  public set MandantId(id: number) {
    this._selectedMandantId$.next(id);
  }

  public get backendVersion(): null | IVersion {
    const backendVersion = this._backendVersion$.getValue();
    if (!backendVersion) {
      return null;
    }

    return {
      version: backendVersion.version,
      uptime: DateTime.fromSeconds(Math.abs(+backendVersion.uptime)).toFormat(
        'HH:mm:ss'
      ),
    };
  }

  ngOnDestroy(): void {
    this.storageListenerUnlisten();
  }

  public runInitialLoginSequence(): Promise<void> {
    if (location.pathname !== '/') {
      this.InitialRequestedUrl = location.pathname;
    }

    this.oAuthService.setupAutomaticSilentRefresh();

    return (
      this.oAuthService
        .loadDiscoveryDocument()
        // 1. HASH LOGIN:
        // Try to log in via hash fragment after redirect back
        // from IdServer from initImplicitFlow:
        .then(() => this.oAuthService.tryLogin())

        .then(() => {
          this._hasValidToken$.next(this.oAuthService.hasValidAccessToken());
          if (this.oAuthService.hasValidAccessToken()) {
            return Promise.resolve();
          }

          this.login();
          return Promise.resolve();
        })

        .then(() => {
          // Check for the strings 'undefined' and 'null' just to be sure. Our current
          // login(...) should never have this, but in case someone ever calls
          // initImplicitFlow(undefined | null) this could happen.
          if (
            this.oAuthService.state &&
            this.oAuthService.state !== 'undefined' &&
            this.oAuthService.state !== 'null'
          ) {
            let stateUrl = this.oAuthService.state;
            if (stateUrl.startsWith('/') === false) {
              stateUrl = decodeURIComponent(stateUrl);
            }
            console.log(
              `There was state of ${this.oAuthService.state}, so we are sending you to: ${stateUrl}`
            );
            this.router.navigateByUrl(stateUrl);
          }

          this._hasValidToken$.next(this.oAuthService.hasValidAccessToken());
        })
        .catch(() => console.log('Some Error occured'))
    );
  }

  public login(targetUrl?: string) {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.

    console.log('Login Start ', targetUrl, this.router.url);
    this.oAuthService.initLoginFlow(targetUrl || this.router.url);
  }

  public logout() {
    this.MandantId = 0;
    this.oAuthService.logOut();
  }

  private getInitialMandantId(): number {
    const mandantId = parseInt(sessionStorage.getItem('mandantId') || '', 10);

    // Check if mandantId is a number (since getItem can return null if the key does not exist)
    if (!isNaN(mandantId)) {
      return mandantId;
    } else {
      return 0;
    }
  }
}

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const isLoggedIn = this.authService.isAuthenticated;
    const isAuthForm = ['mandant'].includes(
      route.routeConfig?.path || defaultPath
    );

    console.log('State Url', state.url);
    if (!isLoggedIn) {
      this.authService.InitialRequestedUrl = state.url;
    }

    console.log('Is Logged in', isLoggedIn, route.routeConfig?.path);
    if (isLoggedIn && this.authService.MandantId < 1) {
      if (isAuthForm === false) {
        this.router.navigate(['mandant']);
        return false;
      }
      return true;
    }

    return isLoggedIn;
  }
}
